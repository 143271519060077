import { CookieConsent } from './contexts';

export const AWS_AMPLIFY_CONFIG = {
  ssr: true,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: process.env.AWS_AMPLIFY_REGION,
  userPoolId: process.env.AWS_AMPLIFY_REGION_USERPOOLID,
  identityPoolId: process.env.AWS_AMPLIFY_REGION_IDENTITYPOOLID,
  userPoolWebClientId: process.env.AWS_AMPLIFY_REGION_CLIENTID,
};

export const INTERNAL_EMAIL_DOMAINS = ['@whiteawaygroup.com', '@whiteaway.com'];

export const COGNITO_CONFIG = {
  clientId: process.env.AWS_AMPLIFY_REGION_CLIENTID!,
  domain: process.env.COGNITO_DOMAIN!,
};

export const API = {
  bff: process.env.API_B2B_BFF_URL,
};

export const IMAGES_CDN = {
  cdnUrl: process.env.NEXT_PUBLIC_CDN_IMAGES_URL,
};

/**
 * Routings consts used for navigation
 */
export const PATHS = {
  HOME: '',
  PROFILE: 'profile',
  ORDERS: 'orders',
  INVITE_USER: 'invite-user',
  INVOICES: 'invoices',
  SUPPORT: 'support',
  CART: 'cart',
  SIGNIN: 'signin',
  RESEND_ACTIVATION_LINK: 'resend-activation-link',
  FORGOTPASSWORD: 'forgot-password',
  SIGNUP: 'signup',
  HELP: 'help',
  CATEGORIES: 'categories',
  SERVICES: 'services',
  DELIVERY: 'delivery',
  SEARCH: 'search',
  PRODUCT: 'product',
  TERMS: 'terms',
};

export const ROUTES = {
  PROTECTED: {
    CART: `/${PATHS.CART}`,
    HOME: `/${PATHS.HOME}`,
    ORDERS: `/${PATHS.ORDERS}`,
    INVITE_USER: `/${PATHS.INVITE_USER}`,
    INVOICES: `/${PATHS.INVOICES}`,
    SUPPORT: `/${PATHS.SUPPORT}`,
    PRODUCT: `/${PATHS.PRODUCT}`,
    CHECKOUT: {
      SERVICES: `/${PATHS.SERVICES}`,
      DELIVERY: `/${PATHS.DELIVERY}`,
    },
    CATEGORIES: `/${PATHS.CATEGORIES}`,
    SEARCH: `/${PATHS.SEARCH}`,
  },
  AUTH: {
    SIGNIN: `/${PATHS.SIGNIN}`,
    FORGOTPASSWORD: `/${PATHS.FORGOTPASSWORD}`,
    RESEND_ACTIVATION_LINK: `/${PATHS.RESEND_ACTIVATION_LINK}`,
    SIGNUP: `/${PATHS.SIGNUP}`,
  },
  PUBLIC: {
    HELP: `/${PATHS.HELP}`,
    TERMS: `/${PATHS.TERMS}`,
  },
};

/**
 * The cache tags can be used to cache api calls under a certain identifier.
 * This makes it possible to clear the cache again after a mutation.
 */
export const CACHE_TAGS = {
  PROFILE: 'profile',
};

/**
 * Cookie consent settings
 */
export const COOKIE_CONSENT_SETTINGS = {
  id: 'CookieConsent',
  src: 'https://policy.app.cookieinformation.com/uc.js',
  dataCulture: 'DA',
};

/**
 * The cookie consent types
 */
export const COOKIE_CONSENT = {
  MARKETING: 'marketing',
  NECCESSARY: 'necessary',
  FUNCTIONAL: 'functional',
  STATISTIC: 'statistic',
  UNCLASSIFIED: 'unclassified',
} as const satisfies Record<string, CookieConsent>;

/**
 * datadog tracking consent types
 */
export const TRACKING_CONSENT = {
  GRANTED: 'granted',
  NOT_GRANTED: 'not-granted',
} as const;

/**
 * The currency used on the website
 */
export const CURRENCY = 'DKK';

/**
 * The language tag indicating the language of the website
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
 */
export const LANGUAGE_TAG = 'da';

/**
 * The market config used mainly for tracking
 */
export const MARKET_CONFIG = {
  language: LANGUAGE_TAG,
  currency: CURRENCY,
  country: 'DK',
  type: 'b2b',
  code: 'erhverv',
};

/**
 * The config used for initialising datadog
 * @param environment The environment the datadog events are tagged with
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
 */
export const DATADOG_CONFIG = (environment: string) =>
  ({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_API_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_ID as string,
    site: 'datadoghq.com',
    service: 'b2b-shop',
    version: process.env.NEXT_PUBLIC_DATADOG_VERSION as string,
    env: environment,
    /**
     * Percentage of sessions being tracked
     */
    sessionSampleRate: 100,
    /**
     * Enables tracking of resource events
     */
    trackResources: true,
    /**
     * Enables tracking long tasks
     */
    trackLongTasks: true,
    /**
     * All user input is masked, e.g. hunter2 = * * * *
     */
    defaultPrivacyLevel: 'mask-user-input',
    /**
     * allows or dis-allows tracking consent
     */
    trackingConsent: TRACKING_CONSENT.NOT_GRANTED,
  }) as const;

export enum GTM_EVENTS {
  /**
   * This event is used to track the users selected shipping option.
   * The event is fired when a user presses the "Bestil produkter" button on the checkout page.
   */
  ADD_SHIPPING_INFO = 'add_shipping_info',
  /**
   * The event is fired when a user adds an item to the cart.
   */
  ADD_TO_CART = 'add_to_cart',
  /**
   * The event is fired when a user starts the checkout process.
   */
  BEGIN_CHECKOUT = 'begin_checkout',
  /**
   * This event is fired when a succesfull purchase is made.
   */
  PURCHASE = 'purchase',
  /**
  /* The event is fired when a user removes an item from the cart.
   */

  REMOVE_FROM_CART = 'remove_from_cart',
  /**
   * The event is fired when a user selects an item. For example, a user clicks on an item in a PLP.
   */
  SELECT_ITEM = 'select_item',
  /**
   * The event is fired when a user views the cart page.
   */
  VIEW_CART = 'view_cart',
  /**
   * The event is fired when a user views a single item.
   */
  VIEW_ITEM = 'view_item',
  /**
   * The event is fired when a user views a list of items such as on the categories page or search page.
   */
  VIEW_ITEM_LIST = 'view_item_list',
  /**
   * The event is fired when a user searches for an item.
   */
  SEARCH = 'search',
}

/**
 * The search param used to indicate that the user is sorting an item list.
 */
export const SORT_SEARCH_PARAM = 'sorting';
